import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ToastController } from '@ionic/angular/standalone';
import { OidcSecurityService, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';
import { InvalidRoleError } from '../errors/invalid-role.error';
import { LoggerService } from '../service/internal/logger.service';
import { StoragePersistenceService } from '../service/internal/storage-persistence.service';
import { OAuthService } from '../service/oauth/oauth.service';
import { UserService } from '../service/user/user.service';
import { CHECK_USER_ROLES } from '../with-user-roles.provider';

const STORAGE_KEY = 'redirect';

export function oauthAuthenticationGuardWithConfig(configId: string): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    oauthAuthenticationGuard(route, state, configId);
}

export async function oauthAuthenticationGuard(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  configId?: string,
): Promise<boolean | UrlTree> {
  const storage = inject(StoragePersistenceService);
  const loggerService = inject(LoggerService);
  const oidcSecurityService = inject(OidcSecurityService);
  const userService = inject(UserService);
  const oAuthService = inject(OAuthService);
  const toastController = inject(ToastController);
  let config =
    oidcSecurityService.getConfigurations().find((item) => item.configId === configId) ??
    oidcSecurityService.getConfigurations()[0];

  const checkUserRolesFn = inject(CHECK_USER_ROLES, { optional: true });

  try {
    const authenticated = await oAuthService.isAuthenticated();

    if (!authenticated) {
      saveOriginUrl(state, storage, config);
      return redirectToForbiddenPage(oAuthService, loggerService, config, state);
    }

    if (checkUserRolesFn) {
      const promise: void | Promise<void> = checkUserRolesFn(
        await firstValueFrom(userService.currentUser()),
        route,
      );

      if (promise instanceof Promise) {
        await promise;
      }
    }

    return authenticated;
  } catch (error) {
    saveOriginUrl(state, storage, config);
    if (error instanceof InvalidRoleError) {
      await Promise.all([handleInvalidRoleError(toastController), oAuthService.logout()]);
    }
    return redirectToForbiddenPage(oAuthService, loggerService, config, state);
  }
}

/**
 * This method is executed at the start of the Guard's authentication.
 * It saves the original URL into the local storage, so we can redirect the user
 * back to that URL after authentication.
 */
function saveOriginUrl(
  state: RouterStateSnapshot,
  storage: StoragePersistenceService,
  config: OpenIdConfiguration,
): void {
  if (!state.url.match(`.*(login|authenticate|${config.redirectUrl}).*`)) {
    storage.write(STORAGE_KEY, state.url, config);
  }
}

function handleInvalidRoleError(toastController: ToastController): Promise<void> {
  if (toastController) {
    return toastController
      .create({
        message: 'Je hebt niet de juiste rechten om in te loggen.',
        duration: 5000,
        color: 'danger',
        position: 'bottom',
      })
      .then((toast) => toast.present());
  }
  return Promise.resolve();
}

function redirectToForbiddenPage(
  oAuthService: OAuthService,
  loggerService: LoggerService,
  config: OpenIdConfiguration,
  state: RouterStateSnapshot,
): boolean | UrlTree {
  const forbiddenUrl = oAuthService.getForbiddenUrlTree();
  if (!forbiddenUrl) {
    loggerService.logDebug(
      config,
      `There's no forbidden route configured, not redirecting to forbidden page.`,
    );
    return false;
  }
  if (state.url === forbiddenUrl.toString()) {
    loggerService.logDebug(
      config,
      `We're already on the forbidden page (${forbiddenUrl.toString()}) , not redirecting again.`,
    );

    return false;
  }

  loggerService.logDebug(
    config,
    `User is not authenticated. Redirecting to forbidden page: ${forbiddenUrl.toString()}.`,
  );
  return forbiddenUrl;
}
