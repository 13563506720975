/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnvironmentProviders, Provider } from '@angular/core';
import { UserInterface } from '@techniek-team/common';
import { OAuthServiceMock, providePresetUser, UserService, UserServiceMock } from '../index';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { provideTtAuth } from './auth.provider';
import { LyceoOauthInputConfig } from './config/lyceo-oauth.config';
import { OidcOauthInputConfig } from './config/oidc-oauth.config';
import { LoggerService } from './service/internal/logger.service';
import { LoggerServiceMock } from './service/internal/logger.service.mock';
import { LocalSecurityStorage } from './service/storage/local-security.storage';
import { LocalSecurityStorageMock } from './service/storage/local-security.storage.mock';
import { OidcSecurityServiceMock } from './testing/oidc-security-service.mock';
import { withLocalStorage } from './with-local-storage.provider';
import { withUserRoles } from './with-user-roles.provider';

export function provideTtAuthTesting<T extends UserInterface>(
  options:
    | (LyceoOauthInputConfig<T> | OidcOauthInputConfig<T>)
    | (LyceoOauthInputConfig<T> | OidcOauthInputConfig<T>)[],
  ...features: (
    | (Provider | EnvironmentProviders)[]
    | ReturnType<typeof withUserRoles>
    | ReturnType<typeof withLocalStorage>
  )[]
) {
  return [
    provideTtAuth(options, features),
    { provide: LocalSecurityStorage, useClass: LocalSecurityStorageMock },
    { provide: OidcSecurityService, useClass: OidcSecurityServiceMock },
    { provide: LoggerService, useClass: LoggerServiceMock },
    { provide: UserService, useClass: UserServiceMock },
    { provide: OAuthServiceMock, useClass: OAuthServiceMock },
    providePresetUser(),
  ];
}
