/* istanbul ignore file */
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { authWithWhitelistedRoutesInterceptor } from '../interceptors/auth-with-whitelisted-routes.interceptor';

/**
 * @deprecated use {@link authWithWhitelistedRoutesInterceptor } instead. Will be removed in next major version v17
 */
/* istanbul ignore next */
export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  return authWithWhitelistedRoutesInterceptor(req, next);
}
