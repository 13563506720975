import { EnvironmentProviders, InjectionToken, Provider } from '@angular/core';
import { UserInterface } from '@techniek-team/common';
import {
  OidcSecurityService,
  PopUpService,
  provideAuth,
  withAppInitializerAuthCheck,
} from 'angular-auth-oidc-client';
import { buildConfig } from './config/functions/build-auth-config.function';
import { LyceoOauthConfig, LyceoOauthInputConfig } from './config/lyceo-oauth.config';
import { OidcOauthConfig, OidcOauthInputConfig } from './config/oidc-oauth.config';
import { InAppBrowserWebService } from './service/in-app-browser/in-app-browser-web.service';
import { InAppBrowserService } from './service/in-app-browser/in-app-browser.service';
import { OAuthService } from './service/oauth/oauth.service';
import { UserService } from './service/user/user.service';
import { withLocalStorage } from './with-local-storage.provider';
import { withUserRoles } from './with-user-roles.provider';

export const TT_AUTH_CONFIGS = new InjectionToken<
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  (LyceoOauthConfig<any, any> | OidcOauthConfig<any, any>)[]
>('auth.configs');

export function provideTtAuth<T extends UserInterface>(
  options:
    | (LyceoOauthInputConfig<T> | OidcOauthInputConfig<T>)
    | (LyceoOauthInputConfig<T> | OidcOauthInputConfig<T>)[],
  ...features: (
    | (Provider | EnvironmentProviders)[]
    | ReturnType<typeof withUserRoles>
    | ReturnType<typeof withLocalStorage>
  )[]
) {
  let config: OidcOauthConfig<T> | OidcOauthConfig<T>[];
  if (Array.isArray(options)) {
    config = [];
    for (let option of options) {
      config.push(buildConfig(option));
    }
  } else {
    config = buildConfig(options);
  }

  return [
    provideAuth(
      {
        config: config,
      },
      withAppInitializerAuthCheck(),
    ),
    {
      provide: TT_AUTH_CONFIGS,
      useFactory: retrieveConfig(config),
      deps: [OidcSecurityService],
    } as Provider,
    InAppBrowserWebService,
    {
      provide: InAppBrowserService,
      useExisting: InAppBrowserWebService,
    },
    { provide: PopUpService, useExisting: InAppBrowserService },
    OAuthService,
    UserService,
    ...features,
  ];
}

function retrieveConfig<T extends UserInterface>(
  config: OidcOauthConfig<T> | OidcOauthConfig<T>[],
) {
  return (oidcSecurityService: OidcSecurityService) => {
    if (Array.isArray(config)) {
      return config.map((foundConfig) => ({
        ...foundConfig,
        ...(oidcSecurityService.getConfiguration(foundConfig.configId) ?? {}),
      }));
    }
    return [{ ...config, ...(oidcSecurityService.getConfiguration(config.configId) ?? {}) }];
  };
}
