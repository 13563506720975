import { OpenIdConfiguration } from 'angular-auth-oidc-client';

export class LoggerServiceMock {
  public logError: (
    configuration: OpenIdConfiguration,
    message: string | object,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ) => void = jest.fn();

  public logWarning: (
    configuration: OpenIdConfiguration,
    message: string | object,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ) => void = jest.fn();

  public logDebug: (
    configuration: OpenIdConfiguration,
    message: string | object,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ) => void = jest.fn();
}
