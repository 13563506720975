import { LogLevel } from 'angular-auth-oidc-client';
import { LyceoUser } from '../models/lyceo-user.model';
import { OidcOauthConfig } from './oidc-oauth.config';

export const configDefaults: OidcOauthConfig = {
  postLogoutRedirectUri: window.location.origin,
  scope: 'openid profile email offline_access',
  responseType: 'code',
  silentRenew: true,
  ignoreNonceAfterRefresh: true,
  useRefreshToken: true,
  renewTimeBeforeTokenExpiresInSeconds: 30,
  logLevel: LogLevel.Debug,
  model: LyceoUser,
  autoUserInfo: true,
  renewUserInfoAfterTokenRenew: true,
};
