import { AbstractSecurityStorage } from 'angular-auth-oidc-client';

export class LocalSecurityStorageMock implements AbstractSecurityStorage {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private storage: Record<string, any> = {};

  private readonly storagePrefix: string = 'mocked';

  public read: (key: string) => string | null = jest.fn((key: string) => {
    return this.storage[`${this.storagePrefix}/auth/${key}`];
  });

  public write: (key: string, value: string) => void = jest.fn((key: string, value: string) => {
    this.storage[`${this.storagePrefix}/auth/${key}`] = value;
  });

  public remove: (key: string) => void = jest.fn((key: string) => {
    this.storage[`${this.storagePrefix}/auth/${key}`] = undefined;
  });
  public clear: () => void = jest.fn(() => {
    this.storage = {};
  });
}
