import { computed, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { UserInterface } from '@techniek-team/common';
import { isDefined } from '@techniek-team/rxjs';
import { Observable } from 'rxjs';
import { LyceoUser } from '../../models/lyceo-user.model';

export class UserServiceMock<T extends UserInterface = LyceoUser> {
  public setUser = signal<T | undefined>(undefined);

  private user$ = toObservable(this.setUser);

  public readonly user = computed(() => {
    return this.setUser();
  });

  public getUser: () => Promise<T | undefined> = jest.fn(() => {
    return Promise.resolve(this.setUser());
  });

  public currentUser: () => Observable<T> = jest.fn(() => {
    return this.user$.pipe(isDefined());
  });

  public getRoles: () => Promise<string[]> = jest.fn(() => {
    return Promise.resolve(this.setUser()?.roles ?? []);
  });
}
