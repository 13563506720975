import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { ADMIN_ACCOUNT } from './models/lyceo-user.model.mock';
import { LyceoUser } from './models/lyceo-user.model';
import { UserService } from './service/user/user.service';
import { UserServiceMock } from './service/user/user.service.mock';

export function providePresetUser(): Provider | EnvironmentProviders[] {
  return [
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: (userService: UserService) => {
        return () => {
          (userService as unknown as UserServiceMock<LyceoUser>).setUser.set(ADMIN_ACCOUNT);
        };
      },
      multi: true,
      deps: [UserService],
    },
  ];
}
