import { UserInterface } from '@techniek-team/common';

export interface OauthProfileResponse extends UserInterface {
  id: string;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  person_id: string;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  skoleo_id?: number;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  first_name: string;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  middle_name?: string;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  last_name: string;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  full_name?: string;
  email: string;
  roles: string[];
}

export class LyceoUser implements UserInterface {
  public readonly id: string;

  public readonly firstName: string;

  public readonly middleName?: string;

  public readonly lastName: string;

  public readonly email: string;

  public readonly roles: string[];

  public readonly fullName: string;

  public readonly skoleoId?: string;

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(user: OauthProfileResponse, ...args: any[]) {
    this.id = user.person_id as string;
    this.skoleoId = user.id;
    this.firstName = user.first_name;
    this.middleName = user.middle_name;
    this.lastName = user.last_name;
    this.email = user.email;
    this.roles = user.roles;
    this.fullName = this.setFullName(user.full_name);
  }

  private setFullName(given: string | undefined): string {
    if (given) {
      return given;
    }

    if (this.middleName) {
      return `${this.firstName} ${this.middleName} ${this.lastName}`;
    }
    return `${this.firstName} ${this.lastName}`;
  }
}
