/* istanbul ignore file */
import { Injectable, inject } from '@angular/core';
import { AbstractLoggerService, LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

/**
 * This is an exact copy of the loggerService of the angular-auth-oidc-client packaage which should
 * be made public but wasn't.
 * https://github.com/damienbod/angular-auth-oidc-client/blob/main/projects/angular-auth-oidc-client/src/lib/logging/logger.service.ts
 */
@Injectable({ providedIn: 'root' })
export class LoggerService {
  private readonly abstractLoggerService = inject(AbstractLoggerService);

  public logError(
    configuration: OpenIdConfiguration,
    message: string | object,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ): void {
    if (this.loggingIsTurnedOff(configuration)) {
      return;
    }

    const { configId } = configuration;
    const messageToLog = this.isObject(message) ? JSON.stringify(message) : message;

    if (!!args && !!args.length) {
      this.abstractLoggerService.logError(`[ERROR] ${configId} - ${messageToLog}`, ...args);
    } else {
      this.abstractLoggerService.logError(`[ERROR] ${configId} - ${messageToLog}`);
    }
  }

  public logWarning(
    configuration: OpenIdConfiguration,
    message: string | object,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ): void {
    if (!this.logLevelIsSet(configuration)) {
      return;
    }

    if (this.loggingIsTurnedOff(configuration)) {
      return;
    }

    if (!this.currentLogLevelIsEqualOrSmallerThan(configuration, LogLevel.Warn)) {
      return;
    }

    const { configId } = configuration;
    const messageToLog = this.isObject(message) ? JSON.stringify(message) : message;

    if (!!args && !!args.length) {
      this.abstractLoggerService.logWarning(`[WARN] ${configId} - ${messageToLog}`, ...args);
    } else {
      this.abstractLoggerService.logWarning(`[WARN] ${configId} - ${messageToLog}`);
    }
  }

  public logDebug(
    configuration: OpenIdConfiguration | null,
    message: string | object,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ): void {
    if (!configuration) {
      return;
    }

    if (!this.logLevelIsSet(configuration)) {
      return;
    }

    if (this.loggingIsTurnedOff(configuration)) {
      return;
    }

    if (!this.currentLogLevelIsEqualOrSmallerThan(configuration, LogLevel.Debug)) {
      return;
    }

    const { configId } = configuration;
    const messageToLog = this.isObject(message) ? JSON.stringify(message) : message;

    if (!!args && !!args.length) {
      this.abstractLoggerService.logDebug(`[DEBUG] ${configId} - ${messageToLog}`, ...args);
    } else {
      this.abstractLoggerService.logDebug(`[DEBUG] ${configId} - ${messageToLog}`);
    }
  }

  private currentLogLevelIsEqualOrSmallerThan(
    configuration: OpenIdConfiguration,
    logLevelToCompare: LogLevel,
  ): boolean {
    const { logLevel } = configuration || {};

    if (!logLevel) {
      return false;
    }

    return logLevel <= logLevelToCompare;
  }

  private logLevelIsSet(configuration: OpenIdConfiguration): boolean {
    const { logLevel } = configuration || {};

    if (logLevel === null) {
      return false;
    }

    return logLevel !== undefined;
  }

  private loggingIsTurnedOff(configuration: OpenIdConfiguration): boolean {
    const { logLevel } = configuration || {};

    return logLevel === LogLevel.None;
  }

  private isObject(possibleObject: unknown): boolean {
    return Object.prototype.toString.call(possibleObject) === '[object Object]';
  }
}
