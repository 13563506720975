import { InjectionToken } from '@angular/core';
import { OpenIdConfiguration, PopupOptions } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

export interface InAppBrowserCreateOptions {
  url: string;

  windowName?: string;

  callback?: () => void;
}

export const IN_APP_BROWSER_TOKEN = new InjectionToken<InAppBrowserService>('IN APP BROWSER TOKEN');

export abstract class InAppBrowserService {
  public abstract result$: Observable<{ userClosed: boolean; receivedUrl: string }>;

  public abstract isCurrentlyInPopup(config: OpenIdConfiguration): boolean;

  public abstract openPopUp(
    url: string | null,
    popupOptions: PopupOptions | undefined,
    config: OpenIdConfiguration,
  ): void;

  public abstract sendMessageToMainWindow(url: string, config: OpenIdConfiguration): void;
}
