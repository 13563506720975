/* eslint-disable @typescript-eslint/no-explicit-any */
import { OauthProfileResponse } from './lyceo-user.model';

/**
 * This is the default profile response object we get from the Lyceo-sso server
 * It should be using our Naming-convention but it doesn't.
 *
 * This model is only used as mock.
 */
/* eslint-disable @typescript-eslint/naming-convention */
export const USER_PROFILE_MOCK: OauthProfileResponse = {
  id: 'account_id',
  person_id: 'person_id',
  skoleo_id: 135854,
  first_name: 'Techniek-Team',
  middle_name: '',
  last_name: 'TestTutor',
  full_name: 'Techniek-Team  TestTutor',
  email: 'administrator+tutor@techniek-team.nl',
  roles: [
    'ROLE_USER',
    'ROLE_SKOLEO_LEGACY_BEGELEIDER_ROL',
    'ROLE_SKOLEO_TT_PLANNER_TUTOR',
    'ROLE_SKOLEO_HOMI_COACH',
  ],
};
/* eslint-enable @typescript-eslint/naming-convention */
