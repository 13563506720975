import { OpenIdConfiguration } from 'angular-auth-oidc-client/lib/config/openid-configuration';
import { configDefaults } from '../oidc-oauth-defaults.config';
import { LyceoOauthInputConfig } from '../lyceo-oauth.config';
import { lyceoOauthDefaults } from '../lyceo-oauth-defaults.config';
import { UserModelClassConstructor } from '../user-model-class-constructor.type';
import { LyceoUser, OauthProfileResponse } from '../../models/lyceo-user.model';
import { UserInterface } from '@techniek-team/common';

export function migrationOauthConfigToOidcConfig<
  T extends UserInterface = LyceoUser,
  I extends UserInterface = OauthProfileResponse,
>(
  authOptions: LyceoOauthInputConfig<T, I>,
): OpenIdConfiguration & { model: UserModelClassConstructor<T, I> } {
  const options = { ...lyceoOauthDefaults, ...authOptions } as LyceoOauthInputConfig &
    Required<Pick<LyceoOauthInputConfig, 'model'>>;
  return {
    ...configDefaults,
    model: (options.model ?? configDefaults.model) as unknown as UserModelClassConstructor<T, I>,
    authority: 'https://login.kube.techniek-team.nl/realms/sso',
    redirectUrl: options.redirectUri,
    postLogoutRedirectUri: window.location.origin,
    clientId: options.clientId,
    authWellknownEndpoints: {
      authorizationEndpoint: options?.authorizeUrl ?? `${options.ssoBaseUrl}/authorization`,
      tokenEndpoint: `${options.ssoBaseUrl}/token`,
      userInfoEndpoint: options.profileBaseUrl,
      endSessionEndpoint: `${options.ssoBaseUrl}/logout`,
    },
    unauthorizedRoute: options.loginUrl,
    forbiddenRoute: options.loginUrl,
    // other scope aren't allow by the old sso.
    scope: 'openid',
  };
}
