import { computed, effect, inject, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { UserServiceInterface, UserInterface } from '@techniek-team/common';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserDataResult } from 'angular-auth-oidc-client/lib/user-data/userdata-result';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { TT_AUTH_CONFIGS } from '../../auth.provider';
import { OidcOauthConfig } from '../../config/oidc-oauth.config';
import { LyceoOauthConfig } from '../../config/lyceo-oauth.config';
import { LyceoUser } from '../../models/lyceo-user.model';
import { OAuthService } from '../oauth/oauth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService<L extends UserInterface = LyceoUser> implements UserServiceInterface {
  private readonly oAuthService = inject(OAuthService);

  private readonly oidcSecurityService = inject(OidcSecurityService);

  private readonly configs = inject(TT_AUTH_CONFIGS);

  private readonly alertCtrl = inject(AlertController);

  public readonly user = computed(() => {
    const userData = this.oidcSecurityService.userData();
    return this.buildUserFromResponse(userData);
  });

  public getUser<K extends UserInterface = LyceoUser>(configId?: string): Promise<K | undefined> {
    return firstEmitFrom(this.currentUser<K>(configId).pipe(first()));
  }

  public currentUser<K extends UserInterface = L>(configId?: string): Observable<K> {
    return this.oidcSecurityService.userData$.pipe(
      map((response) => this.buildUserFromResponse<K>(response, configId)),
      isDefined<K>(),
    );
  }

  private buildUserFromResponse<K extends UserInterface = L>(
    response: UserDataResult,
    configId?: string,
  ): K | undefined {
    if (!response.userData || response.allUserData.length === 0) {
      return undefined;
    }

    let config: LyceoOauthConfig<K> | OidcOauthConfig<K> = this.configs[0];
    if (configId) {
      config = this.configs.find((item) => item.configId === configId) ?? this.configs[0];
    }
    if (response.userData && !configId) {
      return this.createModelFromResponse<K>(config, response.userData);
    }
    let data = response.allUserData.find((item) => item.configId === configId);
    if (!data) {
      data = response.allUserData[0];
    }

    return this.createModelFromResponse<K>(config, data.userData);
  }

  public getRoles<K extends UserInterface = L>(configId?: string): Promise<string[]> {
    return firstValueFrom(this.currentUser<K>(configId).pipe(map((user: K) => user.roles)));
  }

  private createModelFromResponse<M extends UserInterface = L>(
    config: LyceoOauthConfig<M> | OidcOauthConfig<M>,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any,
  ): M {
    try {
      //eslint-disable-next-line new-cap
      return new config.model(response);
    } catch (e) {
      return response as unknown as M;
    }
  }

  /**
   * @deprecated use {@link OAuthService.logout } wil be removed in next major release v17
   */
  /* istanbul ignore next */
  public logout(): Promise<boolean> {
    return this.oAuthService.logout();
  }

  /**
   * @deprecated use {@link OAuthService.requestIfTheUserWantsToLogOut } wil be removed in next major release v17
   */
  /* istanbul ignore next */
  public requestIfTheUserWantsToLogOut(): void {
    this.alertCtrl
      .create({
        header: 'Uitloggen',
        message: 'Weet je zeker dat je wilt uitloggen?',
        buttons: [
          {
            text: 'Nee, bedankt',
            role: 'cancel',
          },
          {
            text: 'Ja, graag',
            //eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            handler: /* istanbul ignore next */ () => this.handleLogoutAction(),
          },
        ],
      })
      .then((confirm) => confirm.present());
  }

  /* istanbul ignore next */
  /**
   * @deprecated use {@link OAuthService.handleLogoutAction } wil be removed in next major release v17
   */
  public handleLogoutAction(): Promise<boolean> {
    return firstEmitFrom(this.oidcSecurityService.logoff())
      .then(() => true)
      .catch(() => false);
  }

  /* istanbul ignore next */
  /**
   * @deprecated This code can lead to unexpected result. This method wil be removed in the next major version v17
   * BehaviorSubject containing users profile
   */
  private user$: BehaviorSubject<UserInterface | undefined> = new BehaviorSubject<
    UserInterface | undefined
  >(undefined);

  /* istanbul ignore next */
  /**
   * @deprecated This code can lead to unexpected result. This method wil be removed in the next major version v17
   */
  private setUserSubject = effect(() => {
    const user = this.user();
    if (user) {
      this.user$.next(user);
    }
  });

  /* istanbul ignore next */
  /**
   * @deprecated This code can lead to unexpected result. This method wil be removed in the next major version v17
   * Check if the logged-in user has the provided role
   */
  public hasRole(role: string): boolean {
    const user: UserInterface | undefined = this.user$.getValue();

    if (!user) {
      return false;
    }

    return user.roles.indexOf(role) > -1;
  }

  /* istanbul ignore next */
  /**
   * @deprecated This code can lead to unexpected result. This method wil be removed in the next major version v17
   * Check if the logged-in user has at least one of the provided roles
   */
  public hasOneOfRoles(roles: string[]): boolean {
    const user: UserInterface | undefined = this.user$.getValue();

    if (!user) {
      return false;
    }

    return user.roles.some((item) => roles.includes(item));
  }

  /* istanbul ignore next */
  /**
   * @deprecated This is handled by the oauth package and is only kept because of backward compatibility.
   * This method wil be removed in the next major version v17
   * get the loggedIn user object and emit it to the user behaviorSubject
   */
  public updateProfile<K extends UserInterface>(): Promise<K> {
    return firstValueFrom(this.currentUser()) as Promise<K>;
  }

  /* istanbul ignore next */
  /**
   * @deprecated This code can lead to unexpected result. This method wil be removed in the next major version v17
   * Check if the user has all required roles set
   * as data in de routing.module
   */
  public isGranted(requiredGrants: string[]): boolean {
    const user: UserInterface | undefined = this.user$.getValue();
    if (!user) {
      return false;
    }

    // get all the users roles
    const userGrants: Set<string> = new Set(user.roles);

    // check if the user has all provided grants
    const missingGrants: Set<string> = new Set(
      requiredGrants.filter((grant) => !userGrants.has(grant)),
    );

    return missingGrants.size === 0;
  }
}
