import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, switchMap } from 'rxjs';
import { TT_AUTH_CONFIGS } from '../auth.provider';
import { LyceoOauthConfig } from '../config/lyceo-oauth.config';
import { OidcOauthConfig } from '../config/oidc-oauth.config';
import { LoggerService } from '../service/internal/logger.service';

export function authWithWhitelistedRoutesInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const config = inject(TT_AUTH_CONFIGS);
  const oidcSecurityService = inject(OidcSecurityService);
  const loggerService = inject(LoggerService);

  if (req.url.includes('/token') || req.method === 'JSONP') {
    return next(req);
  }

  if (!config.find((item) => item.whitelist !== undefined)) {
    loggerService.logDebug(config[0], `No whitelist routes to check configured`);

    return next(req);
  }

  const { matchingConfig, matchingRoute } = getConfigIdForClosestMatchingRoute(
    req.url,
    config,
    'whitelist',
  );
  const logDebug = (message: string) => loggerService.logDebug(matchingConfig, message);

  if (matchingConfig) {
    logDebug(
      `'${req.url}' matches configured route '${matchingRoute}' in whitelist so no access token will be added`,
    );
    return next(req);
  }

  return oidcSecurityService.getAccessToken().pipe(
    switchMap((token) => {
      if (!token) {
        logDebug(`Wanted to add token to ${req.url} but found no token: '${token}'`);
        return next(req);
      }

      logDebug(`'${req.url}' matches configured route '${matchingRoute}', adding token`);
      return next(req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) }));
    }),
  );
}

function getConfigIdForClosestMatchingRoute(
  route: string,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  configurations: (LyceoOauthConfig<any, any> | OidcOauthConfig<any, any>)[],
  type: 'whitelist' | 'secureRoutes',
) {
  for (const config of configurations) {
    const routes = config[type];

    for (const configuredRoute of routes ?? []) {
      if (route.startsWith(configuredRoute)) {
        return {
          matchingRoute: configuredRoute,
          matchingConfig: config,
        };
      }
    }
  }

  return {
    matchingRoute: null,
    matchingConfig: null,
  };
}
