/* istanbul ignore file */
import { Injectable, inject } from '@angular/core';
import {
  AbstractLoggerService,
  AbstractSecurityStorage,
  OpenIdConfiguration,
} from 'angular-auth-oidc-client';

interface JsonArray extends Array<JsonValue> {}
interface JsonObject {
  [prop: string]: JsonValue;
}
type JsonValue = boolean | string | number | JsonArray | JsonObject | null;

type StorageKeys =
  | 'authnResult'
  | 'authzData'
  | 'access_token_expires_at'
  | 'authWellKnownEndPoints'
  | 'userData'
  | 'authNonce'
  | 'codeVerifier'
  | 'authStateControl'
  | 'reusable_refresh_token'
  | 'session_state'
  | 'storageSilentRenewRunning'
  | 'storageCodeFlowInProgress'
  | 'storageCustomParamsAuthRequest'
  | 'storageCustomParamsRefresh'
  | 'storageCustomParamsEndSession'
  | 'redirect'
  | 'configIds'
  | 'jwtKeys'
  | 'popupauth';

/**
 * This is an exact copy of the loggerService of the angular-auth-oidc-client package which should
 * be made public but wasn't.
 * https://github.com/damienbod/angular-auth-oidc-client/blob/main/projects/angular-auth-oidc-client/src/lib/logging/logger.service.ts
 */
@Injectable({ providedIn: 'root' })
export class StoragePersistenceService {
  private readonly loggerService = inject(AbstractLoggerService);

  private readonly abstractSecurityStorage = inject(AbstractSecurityStorage);

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public read(key: StorageKeys, config: OpenIdConfiguration): any {
    const storedConfig = this.readConfigStorage(key, config) || {};

    return storedConfig[key];
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public write(key: StorageKeys, value: any, config: OpenIdConfiguration): boolean {
    const storedConfig = this.readConfigStorage(key, config) || {};

    storedConfig[key] = value;

    return this.writeConfigStorage(storedConfig, config);
  }

  public remove(key: StorageKeys, config: OpenIdConfiguration): void {
    const storedConfig = this.readConfigStorage(key, config) || {};

    delete storedConfig[key];

    this.writeConfigStorage(storedConfig, config);
  }

  private readConfigStorage(key: string, configuration: OpenIdConfiguration): JsonObject | null {
    const { configId } = configuration;

    if (!configId) {
      this.loggerService.logDebug(
        configuration,
        `Wanted to read '${key}' but configId was '${configId}'`,
      );

      return null;
    }

    const storedConfig = this.abstractSecurityStorage.read(configId);

    if (!storedConfig) {
      return null;
    }

    return JSON.parse(storedConfig);
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  private writeConfigStorage(value: any, configuration: OpenIdConfiguration): boolean {
    const { configId } = configuration;

    if (!configId) {
      this.loggerService.logDebug(configuration, `Wanted to write but configId was '${configId}'`);

      return false;
    }

    value = value || null;

    this.abstractSecurityStorage.write(configId, JSON.stringify(value));

    return true;
  }
}
