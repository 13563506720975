import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { authInterceptor as secureRoutesAuthInterceptor } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

export function authWithSecureRoutesInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  return secureRoutesAuthInterceptor()(req, next);
}
