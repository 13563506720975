import { computed, inject, Injectable } from '@angular/core';
import { CanActivateFn, Params, Router, UrlTree } from '@angular/router';
import { AlertController, NavController, ToastController } from '@ionic/angular/standalone';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LogoutAuthOptions } from 'angular-auth-oidc-client/lib/auth-options';
import { LoginResponse } from 'angular-auth-oidc-client/lib/login/login-response';
import { EMPTY, firstValueFrom, Observable } from 'rxjs';
import { TT_AUTH_CONFIGS } from '../../auth.provider';
import { StoragePersistenceService } from '../internal/storage-persistence.service';

/**
 * @deprecated all handled by the oath package. Will be removed in the next major release v17.
 */
export interface RedirectAfterLoginOption {
  /**
   * When true the {@link redirectAfterLogin} method wil redirect to the
   * configured home page regardless of the origin. When false it tries to
   * redirect back to the origin URL and uses the configured home page as
   * fallback URL.
   */
  forceRedirectHome?: boolean;

  /**
   * Optional option to pass any query parameters through to the URL it's going to
   * redirect to.
   */
  queryParams?: Params;
}

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  private readonly oidcSecurityService = inject(OidcSecurityService);

  private readonly storage = inject(StoragePersistenceService);

  private readonly navController = inject(NavController);

  private readonly toastController = inject(ToastController);

  private readonly alertController = inject(AlertController);

  private config = inject(TT_AUTH_CONFIGS);

  private router = inject(Router);

  public authorize() {
    this.oidcSecurityService.authorize();
  }

  public authorizeWithPopUp(): Promise<LoginResponse | undefined> {
    return firstEmitFrom(this.oidcSecurityService.authorizeWithPopUp());
  }

  public readonly authenticated = computed(
    () => this.oidcSecurityService.authenticated().isAuthenticated,
  );

  public isAuthenticated(configId?: string): Promise<boolean> {
    return firstValueFrom(this.oidcSecurityService.isAuthenticated(configId));
  }

  public async refreshAccessToken(configId?: string): Promise<boolean> {
    try {
      const response: LoginResponse = await firstValueFrom(
        this.oidcSecurityService.forceRefreshSession(undefined, configId),
      );
      return response.isAuthenticated;
    } catch (error) {
      await this.toastController
        .create({
          message: 'Je sessie is verlopen, log opnieuw in.',
          duration: 2000,
          color: 'dark',
        })
        .then((newToast) => newToast.present());
      return false;
    }
  }

  public async requestIfTheUserWantsToLogOut(
    configId?: string,
    logoutAuthOptions?: LogoutAuthOptions,
  ): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Uitloggen',
      message: 'Weet je zeker dat je wilt uitloggen?',
      buttons: [
        {
          text: 'Nee, bedankt',
          role: 'cancel',
        },
        {
          text: 'Ja, graag',
          handler: /* istanbul ignore next */ () => this.logout(configId, logoutAuthOptions),
        },
      ],
    });
    return alert.present();
  }

  public logout(configId?: string, logoutAuthOptions?: LogoutAuthOptions): Promise<boolean> {
    return firstEmitFrom(this.logoff(configId, logoutAuthOptions))
      .then(() => true)
      .catch(() => false);
  }

  public logoff(configId?: string, logoutAuthOptions?: LogoutAuthOptions): Observable<unknown> {
    return this.oidcSecurityService.logoff(configId, logoutAuthOptions);
  }

  public logoffAndRevokeTokens(
    configId?: string,
    logoutAuthOptions?: LogoutAuthOptions,
  ): Observable<unknown> {
    return this.oidcSecurityService.logoffAndRevokeTokens(configId, logoutAuthOptions);
  }

  public getForbiddenUrlTree(configId?: string): UrlTree | boolean {
    let config = this.getConfig(configId);

    if (!config?.forbiddenRoute) {
      return this.getUnauthorizedUrlTree(configId);
    }

    return this.router.parseUrl(config.forbiddenRoute);
  }

  public getUnauthorizedUrlTree(configId?: string): UrlTree | boolean {
    let config = this.getConfig(configId);

    if (!config?.unauthorizedRoute) {
      return false;
    }

    return this.router.parseUrl(config.unauthorizedRoute);
  }

  private getConfig(configId?: string) {
    let config = this.config[0];
    if (configId) {
      config = this.config.find((item) => item.configId === configId) ?? config;
    }
    return config;
  }

  /**
   * @deprecated Is handled for you so you don't need this. Will be removed in the next major release
   * Method return a {@link UrlTree} for the configured loginUrl.
   *
   * As the {@link CanActivateFn } interface states it should return a UrlTree if the
   * user can't activate and should be redirected to a different route. This method
   * is a helper function that returns such a URL tree for the home or origin page.
   */
  /* istanbul ignore next */
  public getRedirectAfterLoginUrlTree(_options?: RedirectAfterLoginOption): Promise<UrlTree> {
    const config = this.getConfig();
    const redirect = this.storage.read('redirect', config);

    return Promise.resolve(this.router.parseUrl(redirect ?? '/home'));
  }

  /**
   * @deprecated
   * Redirect to the home or origin page after logging in.
   *
   * Note: You probably don't need this method. If you are going to use this
   * in the {@see canActivate }. Use the {@see getRedirectAfterLoginUrlTree} and use that
   * response as return value for the canActivate method.
   *
   * Will be removed in next major release v17
   */
  /* istanbul ignore next */
  public redirectAfterLogin(_options?: RedirectAfterLoginOption): Promise<boolean> {
    return Promise.resolve(true);
  }

  /**
   * @deprecated this function will be replaced in the next major (v17) release with the authorize function
   * Open the Skoleo login screen in the inAppBrowser
   *
   * If the redirect goes to base url of the app. The deeplink package
   * will pick up that url and opens the app. Because deeplinking is
   * not working with ionic serve, we do a localhost check to determine
   * if running ionic serve
   */
  /* istanbul ignore next */
  public initiateAuthCodePKCE(_callback?: () => void, _service?: string): Promise<void> {
    this.oidcSecurityService.authorize();
    return Promise.resolve();
  }

  /* istanbul ignore next */
  /**
   * @deprecated This is handled be the angular-auth-oidc-client so the function will be removed in the next major
   *   release (v17) Exchange the code for an accessToken and store the response in the localstorage
   */
  public exchangeAuthCodePKCE(_code: string): Observable<void> {
    return EMPTY;
  }

  /* istanbul ignore next */
  /**
   * @deprecated use isAuthenticated instead. will be removed in the next major release (v17)
   * Check if user's accessToken is still valid, if it has expired
   * refresh the token
   */
  public hasValidAccessToken(configId?: string): Promise<boolean> {
    return this.isAuthenticated(configId);
  }

  /* istanbul ignore next */
  /**
   * @deprecated use logoffAndRevokeTokens instead. Will be removed in the next major release (v17)
   * Logout by removing all tokens from the localStorage
   */
  public clearAuthenticationTokens(
    clearOriginUrl: boolean = true,
    configId?: string,
  ): Promise<void> {
    let config = this.getConfig(configId);
    if (clearOriginUrl) {
      this.storage.remove('redirect', config);
      return firstEmitFrom(this.logoffAndRevokeTokens(configId)).then(() => {
        /* return void */
      });
    }
    return Promise.resolve();
  }

  /* istanbul ignore next */
  /**
   * @deprecated don't use this but retrieve the forbidden route for the config yourself
   */
  public redirectToLoginPage(): Promise<boolean> {
    return this.navController.navigateRoot(this.getLoginUrlTree());
  }

  /* istanbul ignore next */
  /**
   * @deprecated don't use this but retrieve the forbidden route for the config yourself
   */
  public getLoginUrlTree(configId?: string): UrlTree {
    let config = this.config[0];
    if (configId) {
      config = this.config.find((item) => item.configId === configId) ?? config;
    }
    return this.router.parseUrl(config.forbiddenRoute ?? config.unauthorizedRoute ?? '/forbidden');
  }
}
