/* istanbul ignore file */
import { LyceoOauthInputConfig } from '../config/lyceo-oauth.config';
import { UserModelClassConstructor } from '../config/user-model-class-constructor.type';
import { LyceoUser, OauthProfileResponse } from '../models/lyceo-user.model';
import { UserInterface } from '@techniek-team/common';

/**
 * @deprecated use AuthConfigInterface instead.
 */
/* istanbul ignore next */
export class AuthConfig<
  T extends UserInterface = LyceoUser,
  I extends UserInterface = OauthProfileResponse,
> implements LyceoOauthInputConfig<T, I>
{
  public configId!: string;

  public clientId!: string;

  public ssoBaseUrl!: string;

  public profileBaseUrl!: string;

  public authorizeUrl?: string;

  public baseUrl!: string;

  public redirectUri!: string;

  public loginUrl: string = '/login';

  public homeUrl!: string;

  public whitelist?: string[] = [];

  public model?: UserModelClassConstructor<T, I> =
    LyceoUser as unknown as UserModelClassConstructor<T, I>;
}
