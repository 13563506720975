import { LyceoUser, USER_PROFILE_MOCK } from '../../index';

export const SCHEDULING_TEAM_ACCOUNT: LyceoUser = new LyceoUser({
  ...USER_PROFILE_MOCK,
  roles: ['ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM'],
});

export const LOCATION_MANAGER_ACCOUNT: LyceoUser = new LyceoUser({
  ...USER_PROFILE_MOCK,
  roles: ['ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT'],
});

export const ADMIN_ACCOUNT: LyceoUser = new LyceoUser({
  ...USER_PROFILE_MOCK,
  roles: ['ROLE_SKOLEO_TT_PLANNER_ADMIN'],
});
