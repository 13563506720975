import { signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { VOID } from '@techniek-team/rxjs';
import {
  AuthOptions,
  AuthWellKnownEndpoints,
  PopupOptions,
  LogoutAuthOptions,
  OpenIdConfiguration,
  LoginResponse,
  UserDataResult,
} from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { USER_PROFILE_MOCK } from '../models/oauth-profile.response.mock';
import { OauthProfileResponse } from '../models/lyceo-user.model';

interface AuthResult {
  //eslint-disable-next-line @typescript-eslint/naming-convention
  id_token?: string;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  access_token?: string;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  refresh_token?: string;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;

  //eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-explicit-any
  session_state?: any;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  scope?: string;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  expires_in?: number;
  //eslint-disable-next-line @typescript-eslint/naming-convention
  token_type?: string;
}

export class OidcSecurityServiceMock {
  public userData = signal<UserDataResult>({
    userData: { ...USER_PROFILE_MOCK },
    allUserData: [
      {
        configId: 'testing',
        userData: { ...USER_PROFILE_MOCK },
      },
    ],
  });

  public userData$ = toObservable(this.userData);

  public authenticated = signal({
    isAuthenticated: true,
    allConfigsAuthenticated: [
      {
        configId: 'test-config',
        isAuthenticated: true,
      },
    ],
  });

  public isAuthenticated$ = toObservable(this.authenticated);

  public checkSessionChanged$ = of(true);

  public stsCallback$ = VOID;

  public preloadAuthWellKnownDocument: (configId?: string) => Observable<AuthWellKnownEndpoints> =
    jest.fn((_configId?: string) =>
      of({
        issuer: 'issuer',
        jwksUri: 'jwksUri',
        authorizationEndpoint: 'authorizationEndpoint',
        tokenEndpoint: 'tokenEndpoint',
        userInfoEndpoint: 'userInfoEndpoint',
        endSessionEndpoint: 'endSessionEndpoint',
        checkSessionIframe: 'checkSessionIframe',
        revocationEndpoint: 'revocationEndpoint',
        introspectionEndpoint: 'introspectionEndpoint',
        parEndpoint: 'parEndpoint',
      }),
    );

  public getConfigurations: () => OpenIdConfiguration[] = jest.fn(() => [
    {
      configId: 'test',
    },
  ]);

  public getConfiguration: (configId?: string) => Observable<OpenIdConfiguration | null> = jest.fn(
    (_configId?: string) => of(this.getConfigurations()[0]),
  );

  public getUserData: (configId?: string) => Observable<OauthProfileResponse> = jest.fn(
    (_configId?: string) => this.userData().userData,
  );

  public checkAuth: (url?: string, configId?: string) => Observable<LoginResponse> = jest.fn(
    (_url?: string, _configId?: string) =>
      of({
        isAuthenticated: true,
        userData: this.userData(),
        accessToken: 'accesstoken',
        idToken: 'tokenId',
        configId: 'test',
      }),
  );

  public checkAuthMultiple: (url?: string) => Observable<LoginResponse[]> = jest.fn(
    (_url?: string) =>
      of([
        {
          isAuthenticated: true,
          userData: this.userData(),
          accessToken: 'accesstoken',
          idToken: 'tokenId',
          configId: 'test',
        },
      ]),
  );

  public isAuthenticated: (configId?: string) => Observable<boolean> = jest.fn(
    (_configId?: string) => of(true),
  );

  public checkAuthIncludingServer: (configId?: string) => Observable<LoginResponse> = jest.fn(
    (_configId?: string) =>
      of({
        isAuthenticated: true,
        userData: this.userData(),
        accessToken: 'accesstoken',
        idToken: 'tokenId',
        configId: 'test',
      }),
  );

  public getAccessToken: (configId?: string) => Observable<string> = jest.fn((_configId?: string) =>
    of('c1d1cdb4-3701-4199-9430-3a410a8734e0'),
  );

  public getIdToken: (configId?: string) => Observable<string> = jest.fn((_configId?: string) =>
    of('c1d1cdb4-3701-4199-9430-3a410a8734e0'),
  );

  public getRefreshToken: (configId?: string) => Observable<string> = jest.fn(
    (_configId?: string) => of('c1d1cdb4-3701-4199-9430-3a410a8734e0'),
  );

  public getAuthenticationResult: (configId?: string) => Observable<AuthResult | null> = jest.fn(
    (_configId?: string) => of(null),
  );

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getPayloadFromIdToken: (encode?: boolean, configId?: string) => Observable<any> = jest.fn(
    (_encode?: boolean, _configId?: string) => of({ payload: 'test' }),
  );

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getPayloadFromAccessToken: (encode?: boolean, configId?: string) => Observable<any> =
    jest.fn((_encode?: boolean, _configId?: string) => of({ payload: 'test' }));

  public setState: (state: string, configId?: string) => Observable<boolean> = jest.fn(
    (_state: string, _configId?: string) => of(true),
  );

  public getState: (configId?: string) => Observable<string> = jest.fn((_configId?: string) =>
    of('test'),
  );

  public authorize: (configId?: string, authOptions?: AuthOptions) => void = jest.fn();

  public authorizeWithPopUp: (
    authOptions?: AuthOptions,
    popupOptions?: PopupOptions,
    configId?: string,
  ) => Observable<LoginResponse> = jest.fn(
    (_authOptions?: AuthOptions, _popupOptions?: PopupOptions, _configId?: string) =>
      of({
        isAuthenticated: true,
        userData: this.userData(),
        accessToken: 'accesstoken',
        idToken: 'tokenId',
        configId: 'test',
      }),
  );

  public forceRefreshSession: (
    customParams?: { [key: string]: string | number | boolean },
    configId?: string,
  ) => Observable<LoginResponse> = jest.fn(
    (_customParams?: { [key: string]: string | number | boolean }, configId?: string) =>
      of({
        isAuthenticated: true,
        userData: this.userData(),
        accessToken: 'accesstoken',
        idToken: 'tokenId',
        configId: 'test',
      }),
  );

  public logoffAndRevokeTokens: (
    configId?: string,
    logoutAuthOptions?: LogoutAuthOptions,
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Observable<any> = jest.fn((_configId?: string, _logoutAuthOptions?: LogoutAuthOptions) =>
    of({ test: 'tada' }),
  );

  public logoff: (configId?: string, logoutAuthOptions?: LogoutAuthOptions) => Observable<unknown> =
    jest.fn((_configId?: string, _logoutAuthOptions?: LogoutAuthOptions) => of({ test: 'tada' }));

  public logoffLocal: (_configId?: string) => void = jest.fn();

  public logoffLocalMultiple: () => void = jest.fn();

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public revokeAccessToken: (accessToken?: any, configId?: string) => Observable<any> = jest.fn(
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_accessToken?: any, _configId?: string) => of({ test: 'tada' }),
  );

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  public revokeRefreshToken: (refreshToken?: any, configId?: string) => Observable<any> = jest.fn(
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_refreshToken?: any, _configId?: string) => of({ test: 'tada' }),
  );

  public getEndSessionUrl: (
    customParams?: { [p: string]: string | number | boolean },
    configId?: string,
  ) => Observable<string | null> = jest.fn(
    (_customParams?: { [p: string]: string | number | boolean }, _configId?: string) => {
      return of('https://login.kube.techniek-team.nl/realms/sso/protocol/openid-connect/logout');
    },
  );

  public getAuthorizeUrl: (
    customParams?: { [p: string]: string | number | boolean },
    configId?: string,
  ) => Observable<string | null> = jest.fn(
    (_customParams?: { [p: string]: string | number | boolean }, _configId?: string) => {
      return of('https://login.kube.techniek-team.nl/realms/sso/protocol/openid-connect/authorize');
    },
  );
}
