import { EnvironmentProviders, InjectionToken, Provider, Type } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserInterface } from '@techniek-team/common';

export type CheckUserRolesFnWithDeps<D> = (
  ...deps: D[]
) => (currentUser: UserInterface, route: ActivatedRouteSnapshot) => void | Promise<void>;

export type CheckUserRolesFn = (
  currentUser: UserInterface,
  route: ActivatedRouteSnapshot,
) => void | Promise<void>;

export const CHECK_USER_ROLES = new InjectionToken<CheckUserRolesFn>('auth.checkUserRoles');

export function withUserRoles<T extends Provider, D>(
  fn: CheckUserRolesFn,
): (Provider | EnvironmentProviders)[];
export function withUserRoles<T extends Provider, D>(
  fn: CheckUserRolesFnWithDeps<D>,
  ...deps: T[]
): (Provider | EnvironmentProviders)[];
export function withUserRoles<T extends Provider, D>(
  fn: CheckUserRolesFn | CheckUserRolesFnWithDeps<D>,
  ...deps: T[]
): (Provider | EnvironmentProviders)[] {
  if (!deps || deps.length === 0) {
    return [
      {
        provide: CHECK_USER_ROLES,
        useValue: fn,
      },
    ];
  }
  return [
    {
      provide: CHECK_USER_ROLES,
      useFactory: (...dependencies: D[]) => (fn as CheckUserRolesFnWithDeps<D>)(...dependencies),
      deps: deps,
    },
  ];
}
