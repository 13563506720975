import { OpenIdConfiguration } from 'angular-auth-oidc-client/lib/config/openid-configuration';
import { configDefaults } from '../oidc-oauth-defaults.config';
import { OidcOauthConfig, OidcOauthInputConfig } from '../oidc-oauth.config';
import { LyceoOauthInputConfig } from '../lyceo-oauth.config';
import { UserModelClassConstructor } from '../user-model-class-constructor.type';
import { LyceoUser, OauthProfileResponse } from '../../models/lyceo-user.model';
import { migrationOauthConfigToOidcConfig } from './migration-oauth-config-to-oidc-config.function';
import { UserInterface } from '@techniek-team/common';

export function buildConfig<
  T extends UserInterface = LyceoUser,
  I extends UserInterface = OauthProfileResponse,
>(options: LyceoOauthInputConfig<T, I> | OidcOauthInputConfig<T, I>): OidcOauthConfig<T, I> {
  if ('ssoBaseUrl' in options) {
    return migrationOauthConfigToOidcConfig(options);
  }
  options = options as OidcOauthConfig<T, I>;
  return {
    ...configDefaults,
    ...options,
  } as OpenIdConfiguration & { model: UserModelClassConstructor<T, I> };
}
