import { AbstractSecurityStorage } from 'angular-auth-oidc-client';

export class LocalSecurityStorage implements AbstractSecurityStorage {
  private setKeys: string[] = [];

  private readonly storagePrefix: string;

  constructor(storageName: string) {
    this.storagePrefix = storageName;
  }

  public read(key: string): string | null {
    return localStorage.getItem(`${this.storagePrefix}/auth/${key}`);
  }

  public write(key: string, value: string): void {
    localStorage.setItem(`${this.storagePrefix}/auth/${key}`, value);
    this.setKeys.push(key);
  }

  public remove(key: string): void {
    localStorage.removeItem(`${this.storagePrefix}/auth/${key}`);
  }
  public clear(): void {
    for (let key of this.setKeys) {
      localStorage.removeItem(`${this.storagePrefix}/auth/${key}`);
    }
  }
}
