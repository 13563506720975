import { EnvironmentProviders, Provider } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { LocalSecurityStorage } from './service/storage/local-security.storage';

export function withLocalStorage(storageName: string): (Provider | EnvironmentProviders)[] {
  return [
    {
      provide: AbstractSecurityStorage,
      useFactory: () => new LocalSecurityStorage(storageName),
    },
  ];
}
