import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { OpenIdConfiguration, PopupOptions } from 'angular-auth-oidc-client';
import { Observable, Subject } from 'rxjs';
import { LoggerService } from '../internal/logger.service';
import { StoragePersistenceService } from '../internal/storage-persistence.service';
import { InAppBrowserService } from './in-app-browser.service';

@Injectable()
export class InAppBrowserWebService extends InAppBrowserService {
  private readonly document = inject(DOCUMENT);

  private readonly storagePersistenceService = inject(StoragePersistenceService);

  private readonly loggerService = inject(LoggerService);

  private readonly storageIdentifier = 'popupauth';

  private popUp: Window | null = null;

  private handle = -1;

  private readonly resultInternal$ = new Subject<{ userClosed: boolean; receivedUrl: string }>();

  public get result$(): Observable<{ userClosed: boolean; receivedUrl: string }> {
    return this.resultInternal$.asObservable();
  }

  private get windowInternal(): Window | null {
    return this.document.defaultView;
  }

  public isCurrentlyInPopup(config: OpenIdConfiguration): boolean {
    const popup = this.storagePersistenceService.read(this.storageIdentifier, config);

    const windowIdentifier = this.windowInternal;

    if (!windowIdentifier) {
      return false;
    }

    return (
      Boolean(windowIdentifier.opener) &&
      windowIdentifier.opener !== windowIdentifier &&
      Boolean(popup)
    );
  }

  public openPopUp(
    url: string | null,
    popupOptions: PopupOptions | undefined,
    config: OpenIdConfiguration,
  ): void {
    const optionsToPass = this.getOptions(popupOptions);
    const windowIdentifier = this.windowInternal;

    this.storagePersistenceService.write(this.storageIdentifier, 'true', config);

    if (!windowIdentifier) {
      this.loggerService.logError(config, 'No Window found');
      return;
    }

    if (!url) {
      this.loggerService.logError(config, 'Could not open popup, url is empty');
      return;
    }

    this.popUp = windowIdentifier.open(url, '_blank', optionsToPass);

    if (!this.popUp) {
      this.storagePersistenceService.remove(this.storageIdentifier, config);
      this.loggerService.logError(config, 'Could not open popup');
      return;
    }

    this.loggerService.logDebug(config, 'Opened popup with url ' + url);

    const listener = this.createListener(config);

    windowIdentifier.addEventListener('message', listener, false);

    this.handle = windowIdentifier.setInterval(() => {
      if (this.popUp?.closed) {
        this.resultInternal$.next({ userClosed: true, receivedUrl: '' });
        this.cleanUp(listener, config);
      }
    }, 200);
  }

  private createListener(config: OpenIdConfiguration): (event: MessageEvent) => void {
    const listener = (event: MessageEvent): void => {
      if (!event?.data || typeof event.data !== 'string') {
        return;
      }

      this.loggerService.logDebug(config, 'Received message from popup with url ' + event.data);
      this.resultInternal$.next({ userClosed: false, receivedUrl: event.data });
      this.cleanUp(listener, config);
    };
    return listener;
  }

  public sendMessageToMainWindow(url: string, config: OpenIdConfiguration): void {
    const windowIdentifier = this.windowInternal;

    if (!windowIdentifier) {
      return;
    }

    if (windowIdentifier.opener) {
      const href = windowIdentifier.location.href;

      this.sendMessage(url, href, config, windowIdentifier);
    }
  }

  private cleanUp(listener: (event: MessageEvent) => void, config: OpenIdConfiguration): void {
    const windowIdentifier = this.windowInternal;

    if (!windowIdentifier) {
      return;
    }

    windowIdentifier.removeEventListener('message', listener, false);
    windowIdentifier.clearInterval(this.handle);

    if (this.popUp) {
      this.storagePersistenceService.remove(this.storageIdentifier, config);
      this.popUp.close();
      this.popUp = null;
    }
  }

  private sendMessage(
    url: string,
    href: string,
    config: OpenIdConfiguration,
    windowIdentifier: Window,
  ): void {
    if (!url) {
      this.loggerService.logDebug(config, `Cannot send message to parent, no url: '${url}'`);

      return;
    }

    windowIdentifier.opener.postMessage(url, href);
  }

  private getOptions(popupOptions: PopupOptions | undefined): string {
    const popupDefaultOptions = {
      width: 500,
      height: 500,
      left: 50,
      top: 50,
    };
    const options: PopupOptions = {
      ...popupDefaultOptions,
      ...(popupOptions || {}),
    };
    const windowIdentifier = this.windowInternal;

    if (!windowIdentifier) {
      return '';
    }

    const width = options.width || popupDefaultOptions.width;
    const height = options.height || popupDefaultOptions.height;

    const left: number = windowIdentifier.screenLeft + (windowIdentifier.outerWidth - width) / 2;
    const top: number = windowIdentifier.screenTop + (windowIdentifier.outerHeight - height) / 2;

    options.left = left;
    options.top = top;

    return Object.entries(options)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join(',');
  }
}
