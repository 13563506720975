import { computed, signal } from '@angular/core';
import { DefaultUrlSerializer, Params, UrlTree } from '@angular/router';
import { LogoutAuthOptions } from 'angular-auth-oidc-client/lib/auth-options';
import { ConfigAuthenticatedResult } from 'angular-auth-oidc-client/lib/auth-state/auth-result';
import { LoginResponse } from 'angular-auth-oidc-client/lib/login/login-response';
import { EMPTY, Observable } from 'rxjs';
import { USER_PROFILE_MOCK } from '../../models/oauth-profile.response.mock';

export class OAuthServiceMock {
  public authorize: () => void = jest.fn();

  public authorizeWithPopUp: () => Promise<LoginResponse | undefined> = jest.fn(() =>
    Promise.resolve({
      isAuthenticated: true,
      accessToken: 'accessToken',
      configId: 'token',
      idToken: 'idToken',
      refreshToken: 'refreshToken',
      expiresAt: 1000000000000,
      userData: USER_PROFILE_MOCK,
    } as LoginResponse),
  );

  public readonly setAuthenticated = signal(true);

  public readonly authenticated = computed(() => this.setAuthenticated());

  public isAuthenticated: (configId?: string) => Promise<boolean> = jest.fn(
    (_configId?: string) => {
      const authenticated = this.setAuthenticated();
      return Promise.resolve(authenticated);
    },
  );

  public refreshAccessToken: () => Promise<boolean> = jest.fn(() => Promise.resolve(true));

  public logoff: (configId?: string, logoutAuthOptions?: LogoutAuthOptions) => Observable<unknown> =
    jest.fn((configId?: string, logoutAuthOptions?: LogoutAuthOptions) => {
      return EMPTY;
    });

  public logoffAndRevokeTokens: (
    configId?: string,
    logoutAuthOptions?: LogoutAuthOptions,
  ) => Observable<unknown> = jest.fn((configId?: string, logoutAuthOptions?: LogoutAuthOptions) => {
    return EMPTY;
  });

  public redirectAfterLogin: (options: {
    forceRedirectHome?: boolean;
    queryParams?: Params;
  }) => Promise<boolean> = jest.fn(() => Promise.resolve(true));

  public getForbiddenUrlTree: () => UrlTree = jest.fn(() =>
    new DefaultUrlSerializer().parse('/forbidden'),
  );

  public getUnauthorizedUrlTree: () => UrlTree = jest.fn(() =>
    new DefaultUrlSerializer().parse('/unauthorized'),
  );

  public getRedirectAfterLoginUrlTree: (options: {
    forceRedirectHome?: boolean;
    queryParams?: Params;
  }) => Promise<UrlTree> = jest.fn(() =>
    Promise.resolve(new DefaultUrlSerializer().parse('/dashboard')),
  );
}
